import React from "react";
import SelectionClass from "../components/Professors/SelectionClass/SelectionClass";

function Professor() {
  return (
    
    <SelectionClass>
      
    </SelectionClass>
    
  );
}

export default Professor;
